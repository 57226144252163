import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Containers/Dashboard';
import Languages from '../Containers/Languages';
import { APP_PAGES } from '../pages';
import Search from '../Containers/Search';
import Login from '../Containers/Auth';
import ProtectedRoute from './ProtectedRoute';
import { TechDigestsDetails } from '../Containers/TechDigests/detail';
import { CategoryValueAdds } from '../Containers/CategoryValueAdds';
import { CategoryBlogs } from '../Containers/CategoryBlogs';
import Statistics from '../Containers/Statistics';
import { SubCategoryStatsDetails } from '../Containers/SubCategoryStatsDetails';

const RouteConfig = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.DASHBOARD}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.CATEGORY}
        element={
          <ProtectedRoute>
            <Languages />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.CATEGORY_VALUE_ADDS}
        element={
          <ProtectedRoute>
            <CategoryValueAdds />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.CATEGORY_BLOGS}
        element={
          <ProtectedRoute>
            <CategoryBlogs />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.TECH_DIGEST_DETAILS}
        element={
          <ProtectedRoute>
            <TechDigestsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.SEARCH}
        element={
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.STATISTICS}
        element={
          <ProtectedRoute>
            <Statistics />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.SUBCATEGORY_TEAMS}
        element={
          <ProtectedRoute>
            <SubCategoryStatsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.SUBCATEGORY_VALUE_ADDS}
        element={
          <ProtectedRoute>
            <SubCategoryStatsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={APP_PAGES.SUBCATEGORY_BLOGS}
        element={
          <ProtectedRoute>
            <SubCategoryStatsDetails />
          </ProtectedRoute>
        }
      />
      <Route path='/login' element={<Login />} />
    </Routes>
  );
};

export default RouteConfig;
