import { useParams } from 'react-router-dom';
import { useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { useEffect, useState } from 'react';
import { CategoryBlog } from './CategoryBlog';
import { Container } from '@mui/material';

export const CategoryBlogs = () => {
  const { category } = useParams();
  const [blogs, setBlogs] = useState<any>({});
  const { data } = useFetchOrion(`tech/category/${category}/blogs`);

  useEffect(() => {
    if (category !== undefined) {
      if (data) {
        const result: any[] = data?.technologies?.flatMap((technology: any) => technology.blog);
        setBlogs(result);
      }
    }
  }, [category, data]);
  return (
    <Container>
      <CategoryBlog data={blogs} />
    </Container>
  );
};
