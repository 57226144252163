import axios, { AxiosInstance } from 'axios';

axios.defaults.withCredentials = true;
export const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_KBTECH_API_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (process.env.REACT_APP_STRAPI_ACCESS_TOKEN) {
      config.headers['Authorization'] = `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      alert();
    }
    return res;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(err);
  }
);
