import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFetch, IApiResponse } from '../../../Utils/hooks/useFetch';
import { MarkdownComponent } from '../../atoms/Markdown';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation } from 'swiper';
import { Heading } from '../../Heading';

// Styling for the section and card components;

const UpdateCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '1rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}));

const Test = styled(Box)(({ theme }) => ({}));

export const RecentlUpdates = () => {
  const [recentUpdates, setRecentUpdates] = useState<any[]>([]);
  const { data: dataSet, loading }: IApiResponse = useFetch('/recent-updates');

  useEffect(() => {
    if (dataSet) {
      const today = new Date();
      const sortedUpdates = [...dataSet]
        .filter((item) => {
          const publishedDate = new Date(item.attributes.publishedAt);
          // Filter only items that are published today or in the past
          return publishedDate <= today;
        })
        .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

      setRecentUpdates(sortedUpdates.slice(0, 10));
    }
  }, [dataSet]);

  useEffect(() => {
    console.log('recentUpdates updated:', recentUpdates);
  }, [recentUpdates]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Heading title='Recent Updates' variant='h3' />
      <Swiper modules={[Navigation]} spaceBetween={50} slidesPerView={3} navigation>
        {recentUpdates?.map((update, index) => (
          <SwiperSlide key={index} className='p-0'>
            <UpdateCard sx={{ mb: '3rem' }}>
              <CardContent sx={{ height: '300px', overflow: 'auto' }}>
                <Typography variant='h6' gutterBottom>
                  {update.attributes.title}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  By {update.attributes.author}
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  Published on{' '}
                  {new Date(update.attributes.publishedAt).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Typography>
                <Test>
                  <MarkdownComponent>{update.attributes.content}</MarkdownComponent>
                </Test>
              </CardContent>
            </UpdateCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
