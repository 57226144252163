import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { Teams } from '../Teams';
import { CategoryValueAdd } from '../CategoryValueAdds/CategoryValueAdd';
import { CategoryBlog } from '../CategoryBlogs/CategoryBlog';

export const SubCategoryStatsDetails = () => {
  const location = useLocation();
  const { data, item } = location.state || {};

  const componentsMap: Record<string, React.ReactNode> = {
    valueAdd: <CategoryValueAdd key={Math.random()} valueAdds={data} />,
    teams: <Teams data={data} fromSubCat={true} />,
    blogs: <CategoryBlog data={data} />,
  };

  const content = componentsMap[item];

  return content ? <Container sx={{ marginTop: '1rem' }}>{content}</Container> : null;
};
