import { Box, Card } from '@mui/material';
import React from 'react';
import { NavigationCard } from '../../Components/atoms/Card/navigationCard';
import { HeaderWithIcon } from '../../Components/atoms/HeaderWithIcon';
import Chart from 'react-apexcharts';

interface ArticalListingContentInterface {
  recentData: any[];
  download: boolean;
  isChart: boolean;
  popularSubCategory: any[];
  componentRef: any;
  category: any;
  legendObj: any;
  setChartLoaded: any;
}

export const MaturityContent = ({
  recentData,
  download,
  isChart,
  popularSubCategory,
  componentRef,
  category,
  legendObj,
  setChartLoaded,
}: ArticalListingContentInterface) => {
  const getColour = (item: any) => {
    let popularity = -1;
    item.forEach((i: any) => {
      let index = popularSubCategory.indexOf(i);
      if (index > popularity) {
        popularity = index;
      }
    });
    switch (popularity) {
      case 0:
        return '#FBE9E7';
      case 1:
        return '#FFFDE7';
      case 2:
        return '#E8F5E9';
      case 3:
        return '#E1F5FE';
      default:
        return '#FFFFFF';
    }
  };

  const getLabelColour = (label: number) => {
    let index = popularSubCategory.indexOf(label);
    switch (index) {
      case 0:
        return '#FFCCBC';
      case 1:
        return '#FFF9C4';
      case 2:
        return '#C8E6C9';
      case 3:
        return '#BBDEFB';
      default:
        return '#BDBDBD';
    }
  };

  return (
    <>
      <Box
        className={!download ? 'languageListWrapper' : 'languageListWrapperDownload'}
        ref={componentRef}
        id='capture'
      >
        {recentData && recentData?.length && (
          <>
            {recentData?.map((item: any, index: any) => {
              return (
                <Box key={index} className={!download || !isChart ? 'languageListItem' : 'languageListItemDownload'}>
                  <Card className='card-list'>
                    <HeaderWithIcon title={item?.type} variant='h4' isEndIcon={true} />
                    <Box className='navigation-inner'>
                      {item?.data?.map((content: any) => {
                        let getSlug: any;
                        let datalist = content?.attributes?.tech_categories?.data;
                        getSlug = datalist?.filter((d: any) => d?.attributes?.slug === category);

                        return (
                          <NavigationCard
                            // src={content?.attributes?.src}
                            src={content?.attributes?.icon?.data?.attributes?.url}
                            name={content?.attributes?.name}
                            alt={content?.attributes?.alt}
                            isLearningIcon={content?.attributes?.learning}
                            key={content?.attributes?.name}
                            slug={content?.attributes?.slug}
                            hasOurExpertise={content?.attributes?.our_expertise.data}
                            hasUpdate={content?.attributes?.hasUpdate}
                            link={`/${getSlug[0]?.attributes.slug}/${encodeURIComponent(content?.attributes?.name)}`}
                            expertise_level={content?.attributes?.expertise_level}
                            description={content.attributes?.description}
                            recent_updates={content.attributes?.recent_updates}
                            popularity={content?.attributes?.popularity}
                            download={download}
                            subCategoryColor={getColour(
                              content?.attributes?.tech_sub_categories.data.map((data: any) => data.attributes.name)
                            )}
                            hasSubCategory={isChart}
                          />
                        );
                      })}
                    </Box>
                  </Card>
                </Box>
              );
            })}
            {download && isChart && popularSubCategory?.length > 0 && (
              <Box className={'languageListItemLegend'}>
                <Chart
                  type='pie'
                  width={150}
                  height={500}
                  series={[...Object.values(legendObj)] as number[]}
                  options={{
                    labels: Object.keys(legendObj),
                    legend: {
                      show: true,
                      position: 'bottom',
                      floating: false,
                      showForSingleSeries: true,
                      horizontalAlign: 'left',
                      fontFamily: 'Helvetica, Arial',
                      fontWeight: 400,
                      fontSize: '12px',
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: (val: any, opts): string => {
                        return Math.round(val) + '%';
                      },
                      dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0,
                      },

                      style: {
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ['black'],
                      },
                    },
                    chart: {
                      events: {
                        animationEnd: function (chartContext, config) {
                          setChartLoaded(true);
                        },
                      },
                    },
                    colors: Object.keys(legendObj).map((level: any) => getLabelColour(level)),
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
