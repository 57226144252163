import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { CategoryCard } from '../../atoms/Card/categoryCard';
import { styled } from '@mui/material/styles';
import { CircularLoader } from '../../atoms/CircularLoader';
import { ICategories } from '../../../types/Categories';
import { axiosInstance } from '../../../Utils/AxiosInstance';
import { UserContext } from '../../../Context/UserContext';
import { useFetchOrion } from '../../../Utils/hooks/useFetchOrion';


const KbCategories = styled(Box)(({ theme }) => ({
  marginTop: '-9rem',
  paddingBottom: '4rem',
  '& .noDataText': {
    textAlign: 'center',
    width: '100vw',
    marginTop: '2rem',
  },
}));

export const Categories = () => {
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { homePageData, setHomePageData } = useContext<any>(UserContext);
  const {data:categoriesDataOrion} = useFetchOrion('tech/categories')
  useEffect(() => {
    if (homePageData?.techCategories) {
      setLoading(false);
      setError(false);
      setCategoriesData(homePageData?.techCategories);
    } else {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('tech-categories?populate=deep,3');
          if (response?.status === 200) {
            setLoading(false);
            setError(false);
            setHomePageData({ techCategories: response?.data?.data });
            setCategoriesData(response?.data?.data);
          }
        } catch (err) {
          setError(true);
        }
      };
      fetchData();
    }
  }, []);

  const handleRecentUpdates = (categoryName: string) => {
    if (categoriesData) {
      const category = categoriesData?.find((d: any) => d?.attributes?.name === categoryName);
      const categoryRecentUpdates = category?.attributes?.recent_updates?.data;
      const techDigests = category?.attributes?.tech_digests?.data.filter(
        (td: any) => td?.attributes?.recent_updates?.data?.length > 0
      );
      const techDigestsRecentUpdates: any = [];
      techDigests?.map((t: any) => techDigestsRecentUpdates.push(...t?.attributes?.recent_updates?.data));
      return [...categoryRecentUpdates, ...techDigestsRecentUpdates];
    }
  };
  return (
    <KbCategories>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        {loading ? (
          <CircularLoader />
        ) : error ? (
          <Typography variant='h3' className='noDataText'>
            Something went wrong.
          </Typography>
        ) : categoriesData?.length ? (
          categoriesData
            .sort((a: ICategories, b: ICategories) => a.attributes.index - b.attributes.index)
            .map((item: ICategories, index: any) => {
              const {
                attributes: { name, slug, icon },
              } = item;
              const { url } = icon?.data?.attributes || {};
              return (
                <Grid item xs={6} md={3} key={name}>
                  <CategoryCard
                    item={item}
                    alt={name}
                    icon={url}
                    categoryName={name}
                    link={`/${slug}`}
                    recentUpdates={handleRecentUpdates(name)}
                    stats={categoriesDataOrion && categoriesDataOrion.find((i:any)=>i.name===name)}
                  />
                </Grid>
              );
            })
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </Grid>
      {/* <KbDivider /> */}
    </KbCategories>
  );
};
