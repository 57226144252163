import React from 'react';
import { Box, Typography } from '@mui/material';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { RightSideContent } from '../../Components/molecules/RightSideBar';

import { Banner } from './banner';
interface OurExpertiseInterface {
  data: any;
}

export const TechPoC = ({ data }: OurExpertiseInterface) => {
  return (
    <>
      <RightSideContent>
        {data.data[0]?.attributes ? (
          <>
            {data.data[0]?.attributes?.Title && (
              <Box>
                <Typography variant={'h3'} fontSize={18}>{data.data[0].attributes?.Title}</Typography>
              </Box>
            )}
            <Banner date={data.data[0]?.attributes?.updatedAt} />

            {data.data[0]?.attributes?.Github_Code_Repository_URL && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                  Github Repository:{' '}
                  <a href={data.data[0].attributes?.Github_Code_Repository_URL} target='_blank'>
                    {data.data[0].attributes?.Github_Code_Repository_URL}
                  </a>
                </Typography>
              </Box>
            )}
            {data.data[0]?.attributes?.Demo_Url && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                  Demo Url:{' '}
                  <a href={data.data[0].attributes?.Demo_Url} target='_blank'>
                    {data.data[0].attributes?.Demo_Url}
                  </a>
                </Typography>
              </Box>
            )}
            {data.data[0]?.attributes?.Maturity && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                  Maturity:{data.data[0].attributes?.Maturity}
                </Typography>
              </Box>
            )}
            {data.data[0]?.attributes?.Ease_of_use && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                Ease of use:{data.data[0].attributes?.Ease_of_use}
                </Typography>
              </Box>
            )}
            {data.data[0]?.attributes?.Sweet_spot && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                Sweet Spot:
                </Typography>
                <MarkdownComponent>{data.data[0].attributes?.Sweet_spot}</MarkdownComponent>
                
              </Box>
            )}
            {data.data[0]?.attributes?.Note && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'} fontSize={18}>
                Note:
                </Typography>
                <MarkdownComponent>{data.data[0].attributes?.Note}</MarkdownComponent>
                
              </Box>
            )}
          </>
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </RightSideContent>
    </>
  );
};
