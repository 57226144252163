import { Box, Container } from '@mui/material';
import { Categories } from '../../Components/molecules/Categories';
import { Filters } from '../../Components/molecules/Filters';
import { RecentlyUploadedSection } from '../../Components/molecules/RecentlyUploadedSection';
import { RecentlUpdates } from '../../Components/molecules/RecentUpdates';

const Dashboard = () => {
  return (
    <Box>
      <Filters />
      <Container>
        <Categories />
        <RecentlUpdates />
      </Container>
    </Box>
  );
};

export default Dashboard;
