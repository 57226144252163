import { Box, Card, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import BarChart from './BarChart';
import { statisticsLabel } from '../../Constants/constants';
import { CircularLoader } from '../../Components/atoms/CircularLoader';
import { UserContext, UserContextType } from '../../Context/UserContext';

const Statistics = () => {
  
  const { category } = useContext<UserContextType>(UserContext);

  const [stats, setStats] = useState([
    { name: 'ValueAdd', technologies: [''], counts: [] },
    { name: 'Teams', technologies: [''], counts: [] },
    { name: 'Publications', technologies: [''], counts: [] },
  ]);

  const { data: ValueAddData, loading: ValueAddLoading } = useFetchOrion(
    `tech/category/${category?.name}/value-adds`
  );

  const { data: BlogsData, loading: BlogsLoading } = useFetchOrion(`tech/category/${category?.name}/blogs`);

  const { data: TeamsData, loading: TeamsLoading } = useFetchOrion(`tech/category/${category?.name}/teams`);
  const getData = (
    data: any,
    property: string,
    countKey: string,
    countIsLength: boolean,
    filterCondition: (item: any) => boolean,
    categoryName: string
  ) => {
    if (data && data?.technologies) {
      const filteredData = data?.technologies
        .filter((item: any) => filterCondition(item))
        .map((item: any) => {
          const count = countIsLength ? item[countKey].length : item[countKey];
          return {
            name: item[property],
            count,
          };
        });

      filteredData.sort((a: { count: number }, b: { count: number }) => b.count - a.count);

      const sortedNames = filteredData.map((item: { name: string }) => item.name);
      const sortedCounts = filteredData.map((item: { count: number }) => item.count);

      setStats((prevStats) =>
        prevStats.map((stat) =>
          stat.name === categoryName ? { ...stat, technologies: sortedNames, counts: sortedCounts } : stat
        )
      );
    }
  };

  const chartConfig = [
    {
      id: 'valueAdds',
      title: statisticsLabel.valueAdds,
      xAxisData: stats.find((stat) => stat.name === 'ValueAdd')?.technologies || [],
      barCountData: stats.find((stat) => stat.name === 'ValueAdd')?.counts || [],
      loading: ValueAddLoading,
    },
    {
      id: 'teams',
      title: statisticsLabel.teams,
      xAxisData: stats.find((stat) => stat.name === 'Teams')?.technologies || [],
      barCountData: stats.find((stat) => stat.name === 'Teams')?.counts || [],
      loading: TeamsLoading,
    },
    {
      id: 'publications',
      title: statisticsLabel.publications,
      xAxisData: stats.find((stat) => stat.name === 'Publications')?.technologies || [],
      barCountData: stats.find((stat) => stat.name === 'Publications')?.counts || [],
      loading: BlogsLoading,
    },
  ];

  useEffect(() => {
    setStats([
      // Clear the previous updating
      { name: 'ValueAdd', technologies: [''], counts: [] },
      { name: 'Teams', technologies: [''], counts: [] },
      { name: 'Publications', technologies: [''], counts: [] },
    ]);

    if (ValueAddData) {
      getData(ValueAddData, 'name', 'valueAddsCount', false, (item: any) => item.valueAddsCount > 0, 'ValueAdd');
    }

    if (TeamsData) {
      getData(TeamsData, 'name', 'teams', true, (item: any) => item.teams.length > 0, 'Teams');
    }

    if (BlogsData) {
      getData(BlogsData, 'name', 'blogsCount', false, (item: any) => item.blogsCount > 0, 'Publications');
    }
  }, [category, ValueAddData, TeamsData, BlogsData]);

  return (
    <Container sx={{ pt: '1rem' }}>
      <Box className='wrapper'>
        <Grid container spacing={4}>
          {chartConfig.map(({ id, title, xAxisData, barCountData, loading }) => (
            <Grid item xs={6} key={id}>
              <Card sx={{ flexDirection: 'column', paddingBottom: '1rem' }}>
                <h2>{title}</h2>
                <Box style={{ width: '100%' }}>
                  {loading ? (
                    <CircularLoader />
                  ) : xAxisData.length === 0 || barCountData.length === 0 ? (
                    <Typography variant='h6' align='center'>
                      Currently, there are no {title} available.
                    </Typography>
                  ) : (
                    <BarChart xAxisData={xAxisData.slice(0, 10)} barCountData={barCountData.slice(0, 10)} />
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Statistics;
