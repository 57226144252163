import { Grid, Typography } from '@mui/material';
import React from 'react';
import { TechTalksCard } from '../../Components/atoms/Card/techTalksCard';
import { RightSideContent, RightSideHeader } from '../../Components/molecules/RightSideBar';
import { ITechTalks } from '../../types/TechTalks';

interface TechTalksInterface {
  data: any[];
  tabName: string;
}

export const TechTalks = ({ data, tabName }: TechTalksInterface) => {
  return (
    <>
      <RightSideHeader>{tabName}</RightSideHeader>
      <RightSideContent>
        <Grid container spacing={4}>
          {data?.length ? (
            data.map((item: ITechTalks) => {
              const {
                attributes: { Title, content },
              } = item;

              return (
                <Grid item xs={6} key={Title}>
                  <TechTalksCard url={content} title={Title} />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Typography variant='h3' className='noDataText'>
                Sorry, we couldn't find any results
              </Typography>
            </Grid>
          )}
        </Grid>
      </RightSideContent>
    </>
  );
};
