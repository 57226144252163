export enum APP_PAGES {
  DASHBOARD = '/dashboard',
  CATEGORY = '/:category',
  TECH_DIGEST_DETAILS = '/:category/:technology',
  CATEGORY_VALUE_ADDS = '/:category/value-adds',
  CATEGORY_BLOGS = '/:category/blogs',
  SEARCH = '/search',
  PROFILE = '/profile',
  STATISTICS = '/:category/statistics',
  SUBCATEGORY_TEAMS = '/:category/:subCategory/teams',
  SUBCATEGORY_VALUE_ADDS = '/:category/:subCategory/value-adds',
  SUBCATEGORY_BLOGS = '/:category/:subCategory/blogs',
}
