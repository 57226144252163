import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const KbOgCardUl = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: 0,
}));
const KbOgCardLi = styled('li')(({ theme }) => ({
  listStyle: 'none',
  padding: '1rem',
  cursor: 'pointer',
}));
const KbOgCardLiContent = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: 330,
  borderRadius: 10,
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  height: '100%',
}));

const KbOgDetailsCard = styled('div')(({ theme }) => ({
  padding: '1rem',
}));

const KbOgDetailsCardTitle = styled('h3')(({ theme }) => ({
  margin: 0,
  marginBottom: '.5rem',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5,
}));

const KbOgDetailsCardAuthorInfo = styled('div')(({ theme }) => ({
  '& label, & span': {
    fontSize: '.75rem',
  },
  '& span': {
    fontWeight: 500,
  },
}));

const OgCard = ({ options }: any) => {
  const handleOnClick = () => {
    if (options.link) window.open(options.link, '_blank');
  };
  return (
    <KbOgCardLi onClick={handleOnClick}>
      <KbOgCardLiContent>
        <KbOgDetailsCard>
          <KbOgDetailsCardTitle>{options.title}</KbOgDetailsCardTitle>
          <Box>
            <KbOgDetailsCardAuthorInfo>
              <label>By : </label> <span>{options.author}</span>
            </KbOgDetailsCardAuthorInfo>
            <KbOgDetailsCardAuthorInfo>
              <label>Published at : </label> <span>{options.datePublished}</span>
            </KbOgDetailsCardAuthorInfo>
            <KbOgDetailsCardAuthorInfo>
              <label>Type : </label> <span>{options.type}</span>
            </KbOgDetailsCardAuthorInfo>
          </Box>
        </KbOgDetailsCard>
      </KbOgCardLiContent>
    </KbOgCardLi>
  );
};

export const CategoryBlog = ({ data }: any) => {
  return (
    <>
      {data?.length ? (
        <KbOgCardUl>
          {data.map((d: any, index: number) => {
            return <OgCard key={index} options={d} />;
          })}
        </KbOgCardUl>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, there are no blogs available.
        </Typography>
      )}
    </>
  );
};
