import { Box, Typography, Avatar, useTheme, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { convertExpertiseToNumber } from '../../../Utils/helper';
import Icon from '../../Icons/icons';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { RecentlyUpdatedCard } from '../../../Containers/RecentlyUploaded';
import Cross from '../../../assets/images/cross.png';

import { NavigationProps, NavigationImgProps, ExpertiseProps, drawerProps } from './interfaces';
import { KbNavigationCard, KbExpertise, PopoverContent, RecentUpdateBadge } from './style';
export function renderExpertiseLevel(level = 0) {
  const dots = [];
  const rangeCount: number = 5;
  for (let i = 1; i <= rangeCount; i++) {
    const dotClass =
      level === 1 ? 'low' : level === 2 || level === 3 ? 'avg' : level === 4 || level === 5 ? 'high' : '';

    dots.push(<div key={i} className={`dot ${i <= level ? dotClass : ''}`}></div>);
  }
  return <>{dots}</>;
}

export const KbDrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1.5rem 1rem',
  position: 'sticky',
  top: '0rem',
  zIndex: 10,
  backgroundColor: '#fff',
  borderBottom: '1px solid #D1D6DD',
}));

export const NavigationCard = ({
  name,
  isLearningIcon,
  src,
  alt,
  link,
  expertise_level,
  slug,
  description,
  recent_updates,
  popularity,
  download,
  subCategoryColor,
  hasSubCategory,
  hasOurExpertise,
}: NavigationProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openModal] = React.useState(false);

  const handleOpenDrawer = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenDrawer(true);
  };
  const handleCloseDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenDrawer(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = openModal ? 'simple-popover' : undefined;

  const renderStar = (popularity: number) => {
    let iconKind = 'star-disabled';

    switch (true) {
      // case popularity <= 0:
      //   iconKind = 'star-disabled';
      //   break;
      case popularity === 1:
        iconKind = 'starOne';
        break;
      case popularity === 2:
        iconKind = 'starTwo';
        break;
      default:
        iconKind = 'starThree';
    }

    return <Icon kind={iconKind} size={20} color={theme.palette.primary.main} />;
  };

  return (
    <KbNavigationCard
      paddingInline={0}
      width={'100%'}
      sx={{
        background:
          (!download || !hasSubCategory) && isLearningIcon
            ? '#ffff8f91'
            : download && hasSubCategory
            ? subCategoryColor
            : null,
        padding: !download ? '0.75rem 0 1rem 0' : '0.25rem 0 0.5rem 0',
        marginBottom: !download ? '0.75rem' : '0.25rem',
      }}
    >
      <Box
        style={{ cursor: 'pointer' }}
        alignItems={'center'}
        display={'flex'}
        width={'100%'}
        paddingRight={'.5rem'}
        onClick={() => {
          navigate(link, { state: { data: slug } });
        }}
      >
        <Box className='navigationleftSide' paddingRight={'rem'} marginLeft={'.75rem'}>
          <NavigationImg src={src} alt={alt} name={name} />
        </Box>

        <Box alignItems={'center'} className='navigationRightSide' paddingRight={'.75rem'}>
          <Box className='content'>
            <Typography
              component={'span'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
              {!download && (
                <Box paddingInline={'.5rem'} onClick={handleClick}>
                  <Icon
                    kind='info'
                    size={16}
                    color={theme.palette.primary.main}
                    style={{ cursor: 'pointer', flexShrink: 0 }}
                  />
                </Box>
              )}
            </Typography>
            {/* <Typography variant='caption' display='block'>
            Subcategory placeholder
          </Typography> */}
            {!download && (
              <Box style={{ marginTop: '0.5rem' }}>
                <Expertise expertise_level={expertise_level} />
              </Box>
            )}
          </Box>
          {download && hasSubCategory && isLearningIcon && (
            <Box marginLeft='1rem'>
              <Icon kind={'learning'} size={16} />
            </Box>
          )}
          {popularity ? <Box>{renderStar(popularity)}</Box> : null}
          {download && (
            <Box marginLeft={'1rem'}>
              <Expertise expertise_level={expertise_level} />
            </Box>
          )}
        </Box>
        {hasOurExpertise && (
          <Box className='icon-section'>
            <Icon kind='our-expertise' size={16} color={'#0984C8'} />
          </Box>
        )}
        {!download && recent_updates?.data?.length > 0 && (
          <Box className='icon-section' marginLeft={'1rem'}>
            {recent_updates?.data?.length ? (
              <RecentUpdateBadge onClick={handleOpenDrawer}>{recent_updates?.data?.length}</RecentUpdateBadge>
            ) : null}
          </Box>
        )}
      </Box>
      {/* popover - tooltip */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: '0px',
          textAlign: 'justify',
          height: '12.5rem',
        }}
      >
        <PopoverContent style={{ fontSize: '.875rem', lineHeight: 1.4, textAlign: 'left' }}>
          {description?.slice(0, 280)}...
        </PopoverContent>
      </Popover>

      {/* Drawer */}

      <RecentUpdateDrawer
        openDrawer={openDrawer}
        handleCloseDrawer={handleCloseDrawer}
        name={name}
        data={recent_updates?.data}
        src={src}
        alt={alt}
      />
    </KbNavigationCard>
  );
};

const KbNavigationDefaultImg = styled(Box)(({ theme }) => ({
  padding: '0.288rem',
  backgroundColor: theme.palette.primary.main,

  background: 'linear-gradient(45deg, #0B93DF 0%, #00A3FF 50%,#0984C8 100%)',
  borderRadius: '0.125rem',
  height: '1.875rem',
  width: '1.875rem',
  marginLeft: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  color: 'white',
  fontFamily: 'script',
  fontWeight: 'bold',
  fontSize: '1.25rem',
}));

export const NavigationImg = ({ src, alt, name }: NavigationImgProps) => {
  return src ? (
    <img
      alt={alt}
      crossOrigin='anonymous'
      src={src + `?cache=none`}
      style={{
        height: '1.875rem',
        width: '1.875rem',
        borderRadius: '0.125rem',
        objectFit: 'contain',
      }}
    />
  ) : (
    <KbNavigationDefaultImg>{name ? name[0].toUpperCase() : ''}</KbNavigationDefaultImg>
  );
};

export const Expertise = ({ className, expertise_level }: ExpertiseProps) => {
  return (
    <KbExpertise className={className}>{renderExpertiseLevel(convertExpertiseToNumber(expertise_level!))}</KbExpertise>
  );
};

export const CustomAvatar = ({ name }: any) => {
  function stringToColor(string: string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#4855A9';
    return color;
  }
  function stringAvatar(name: string) {
    let title: string;
    const st = name?.split(' ')?.map((item: string) => item[0]);
    title = st.splice(0, 2).join('');
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${title}`,
    };
  }
  return <Avatar {...stringAvatar(name)} sx={{ borderRadius: '0.125rem' }} />;
};

export function RecentUpdateDrawer({ openDrawer, handleCloseDrawer, name, data, src, alt }: drawerProps) {
  const DrawerList = (
    <Box role='presentation' sx={{ paddingBottom: '6.5rem' }}>
      <KbDrawerHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NavigationImg src={src!} alt={alt!} name={name} />
          <Typography component={'span'} sx={{ ml: 1 }}>
            {name}
          </Typography>
        </div>
        <Button onClick={handleCloseDrawer}>
          <img src={Cross} alt='Cross Icon' />
        </Button>
      </KbDrawerHeader>

      <RecentlyUpdatedCard recentUpdateData={data} />
    </Box>
  );
  return (
    <div>
      <Drawer open={openDrawer} onClose={handleCloseDrawer} anchor={'right'}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
